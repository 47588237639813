import { ApiService } from "./ApiService";
import { Role } from "../../models/api/Role";
import moment from "moment";
import { apiEndPoint } from "../../config";
import { Promise } from "bluebird";

export class RolesApiService extends ApiService {
  constructor() {
    super(apiEndPoint, "roles");
  }

  listRoles(): Promise<Role[]> {
    return new Promise<Role[]>((resolve, reject, cancel) => {
      this.get("", cancel).done((result) => {
        if (result.success) {
          const roles = result.data.map(this.mapDataToRole);
          resolve(roles);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  listAllRoles(): Promise<Role[]> {
    return new Promise<Role[]>((resolve, reject, cancel) => {
      this.get("all", cancel).done((result) => {
        if (result.success) {
          const roles = result.data.map(this.mapDataToRole);
          resolve(roles);
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  createRole(name: string, templateRoleId: string | undefined): Promise<Role> {
    return new Promise<Role>((resolve, reject, cancel) => {
      this.post("", { name, templateRoleId }, cancel).done((result) => {
        if (result.success) {
          resolve(this.mapDataToRole(result.data));
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  updateRole(roleId: string, role: Role): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      role.removedAt = undefined;
      this.post(`${roleId}`, role, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result);
          reject(result.message);
        }
      });
    });
  }

  deleteRole(roldId: string): Promise<void> {
    return new Promise<void>((resolve, reject, cancel) => {
      this.delete(`${roldId}`, cancel).done((result) => {
        if (result.success) {
          resolve();
        } else {
          console.error(result.errorMessage);
          reject(result.message);
        }
      });
    });
  }

  private mapDataToRole(d: any): Role {
    return {
      id: d.ID,
      name: d.NAME,
      canDelete: d.CANDELETE, // Can this role be deleted
      canEdit: d.CANEDIT, // Can this role be edited
      removedAt: moment.utc(d.REMOVEDAT).local(),
      orgId: d.ORGID,
      permissionsRole: d.PERMISSIONSROLE,
      permissionsOrg: d.PERMISSIONSORG,
      permissionsUser: d.PERMISSIONSUSER,
      permissionsBranding: d.PERMISSIONSBRANDING,
      permissionsDashboard: d.PERMISSIONSDASHBOARD,
      permissionsDevice: d.PERMISSIONSDEVICE,
      permissionsSubOrganization: d.PERMISSIONSSUBORGANIZATION,
      permissionsAlert: d.PERMISSIONSALERT,
      permissionsAccessGroup: d.PERMISSIONSACCESSGROUP,
      permissionsDeviceGroup: d.PERMISSIONSDEVICEGROUP,
      permissionsApiKey: d.PERMISSIONSAPIKEY,
      permissionsWebhooks: d.PERMISSIONSWEBHOOKS,
      permissionsEventLog: d.PERMISSIONSEVENTLOG,
      permissionsImport: d.PERMISSIONSIMPORT,
      permissionsAdminMenu: d.PERMISSIONSADMINMENU,
      permissionsPortalLogin: d.PERMISSIONSPORTALLOGIN,
      permissionsDeviceInstallation: d.PERMISSIONSDEVICEINSTALLATION,
      permissionsDeviceMove: d.PERMISSIONSDEVICEMOVE,
      permissionsRCC: d.PERMISSIONSRCC,
    } as Role;
  }
}
