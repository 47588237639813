import { Moment } from "moment";

/**
 * This class is used to represent a theme; returned from the API
 */
export class Role {
  id?: string;
  name: string;
  canDelete: boolean; // Can this role be deleted
  canEdit: boolean; // Can this role be edited
  removedAt?: Moment;
  orgId?: string;

  permissionsRole: PermissionFlags;
  permissionsOrg: PermissionFlags;
  permissionsUser: PermissionFlags;
  permissionsBranding: PermissionFlags;
  permissionsDashboard: PermissionFlags;
  permissionsDevice: PermissionFlags;
  permissionsSubOrganization: PermissionFlags;
  permissionsAlert: PermissionFlags;
  permissionsAccessGroup: PermissionFlags;
  permissionsDeviceGroup: PermissionFlags;
  permissionsApiKey: PermissionFlags;
  permissionsWebhooks: PermissionFlags;
  permissionsEventLog: PermissionFlags;
  permissionsImport: PermissionFlags;
  permissionsDocumentation: PermissionFlags;
  permissionsAdminMenu: PermissionFlags;
  permissionsCustomDashboard: PermissionFlags;
  permissionsProvision: PermissionFlags;
  permissionsBulkOperation: PermissionFlags;
  permissionsTag: PermissionFlags;
  permissionsPortalLogin: EnabledFlags;
  permissionsDeviceInstallation: PermissionFlags;
  permissionsDeviceMove: EnabledFlags;
  permissionsRCC: PermissionFlags;

  constructor(name: string) {
    this.name = name;
    this.canDelete = true;
    this.canEdit = true;

    this.permissionsRole = PermissionFlags.None;
    this.permissionsOrg = PermissionFlags.None;
    this.permissionsUser = PermissionFlags.None;
    this.permissionsBranding = PermissionFlags.None;
    this.permissionsDashboard = PermissionFlags.None;
    this.permissionsDevice = PermissionFlags.None;
    this.permissionsSubOrganization = PermissionFlags.None;
    this.permissionsAlert = PermissionFlags.None;
    this.permissionsAccessGroup = PermissionFlags.None;
    this.permissionsDeviceGroup = PermissionFlags.None;
    this.permissionsApiKey = PermissionFlags.None;
    this.permissionsWebhooks = PermissionFlags.None;
    this.permissionsEventLog = PermissionFlags.None;
    this.permissionsImport = PermissionFlags.None;
    this.permissionsDocumentation = PermissionFlags.None;
    this.permissionsAdminMenu = PermissionFlags.None;
    this.permissionsCustomDashboard = PermissionFlags.None;
    this.permissionsProvision = PermissionFlags.None;
    this.permissionsBulkOperation = PermissionFlags.None;
    this.permissionsTag = PermissionFlags.None;
    this.permissionsPortalLogin = EnabledFlags.Disabled;
    this.permissionsDeviceInstallation = PermissionFlags.None;
    this.permissionsDeviceMove = EnabledFlags.Disabled;
    this.permissionsRCC = PermissionFlags.None;
  }
}

export enum PermissionFlags {
  None = 0,
  Read = 1,
  Write = 2,
  Admin = 4,
}

export function hasWriteFlag(permissionFlag: PermissionFlags): boolean {
  return !((permissionFlag & PermissionFlags.Write) === 0);
}

export enum EnabledFlags {
  Disabled = 0,
  Enabled = 1,
}

export enum FlagType {
  PermissionFlags,
  EnabledFlags,
}

export type PermissionName =
  | "permissionsRole"
  | "permissionsOrg"
  | "permissionsUser"
  | "permissionsBranding"
  | "permissionsDashboard"
  | "permissionsDevice"
  | "permissionsSubOrganization"
  | "permissionsAlert"
  | "permissionsAccessGroup"
  | "permissionsDeviceGroup"
  | "permissionsApiKey"
  | "permissionsWebhooks"
  | "permissionsEventLog"
  | "permissionsImport"
  | "permissionsDocumentation"
  | "permissionsAdminMenu"
  | "permissionsCustomDashboard"
  | "permissionsBulkOperation"
  | "permissionsTag"
  | "permissionsPortalLogin"
  | "permissionsDeviceInstallation"
  | "permissionsDeviceMove"
  | "permissionsRCC";
