import { FC, useRef } from "react";
import JwtTokenHelpers from "../../../helpers/JwtTokenHelpers";
import { useAuth } from "../../../contexts/auth/auth.context";
import { AuthenticationApiService } from "../../../services/api/AuthenticationApiService";
import { Dialog, DialogSize } from "../../Dialog";
import { Button, ButtonType } from "../../Button";
import { useHistory } from "react-router-dom";

type Props = {
  onClose?: () => void;
};

const Logout: FC<Props> = ({ onClose }) => {
  const auth = useAuth();
  const closeDialog = useRef<() => void>(() => {});
  const openDialog = useRef<() => void>(() => {});
  const history = useHistory();
  const handleLogoutClick = () => {
    if (auth.isImpersonated) openDialog.current();
    else handleLogout();
  };
  const getUserName = () => {
    const token = JwtTokenHelpers.getDecodedJwtToken();
    return `${token.firstName} ${token.lastName}`;
  };

  const getUserEmail = () => {
    const token = JwtTokenHelpers.getDecodedJwtToken();
    return token.email;
  };

  const handleLogout = () => {
    JwtTokenHelpers.clear();
    auth.setToken && auth.setToken(undefined);
  };
  const handleImpersonateLogout = () => {
    onClose && onClose();
    const service = new AuthenticationApiService();

    service.impersonateLogout().then((res) => {
      if (res?.token) {
        closeDialog.current();
        localStorage.setItem("JWT_TOKEN", res.token);
        JwtTokenHelpers.setUserToken(res.token);

        const decodedToken = JwtTokenHelpers.getDecodedJwtToken();
        auth.setToken && auth.setToken(decodedToken);
        history.push("/go/welcome");
        // TODO: THIS IS NOT AN IDEAL FIX.
        //  PROBLEM: WHEN USER LOGS OUT ITS IMPERSONATED LOG IN THE CONTENT OF THE HOME PAGE DOES NOT RELOAD
        //  HENCE USER CAN STILL SEE WHAT THE IMPERSONATED HOME PAGE LOOKS LIKE INSTEAD OF ADMIN ACCOUNT. PROFILE PIC DOES NOT RELOAD AS WELL.
        window.location.reload();
      }
    });
  };

  return (
    <>
      <div
        id="link_logouts"
        onClick={handleLogoutClick}
        className="button-dropdown-menu-list-item text-para-default-bold"
      >
        Logout
      </div>

      <Dialog
        size={DialogSize.Large}
        setOpen={(open) => (openDialog.current = open)}
        setClose={(open) => (closeDialog.current = open)}
        onClose={() => {}}
        header=""
        body={
          <div>
            <p className="text-label-large mb-3 impersonated-user-logout-text">
              {`You are currently impersonating `}
              <span className="impersonated-user-logout-details">{getUserName()}</span>
              {` from the account `}
              <span className="impersonated-user-logout-details">{getUserEmail()}</span>
            </p>
            <p className="text-label-large mb-3 impersonated-user-logout-text">{`How would you like to proceed?`}</p>
          </div>
        }
        footer={
          <>
            <span className="flex-grow-1" />
            <Button
              className=" mr-1"
              content="Cancel"
              buttonType={ButtonType.Transparent}
              onClick={() => closeDialog.current()}
            />
            <Button
              className=" mr-1"
              content="Go to previous account"
              buttonType={ButtonType.Success}
              onClick={() => {
                handleImpersonateLogout();
              }}
            />
            <Button content="Logout" buttonType={ButtonType.Error} onClick={handleLogout} />
          </>
        }
      />
    </>
  );
};

export default Logout;
